<template>
  <div>
    <head-bar title="Gewicht toevoegen">
      <template v-slot:left>
        <head-bar-link @click="onBackClick">
          <img src="../../assets/arrow-left.svg" alt="" aria-hidden="true" />
          <span class="visually-hidden">Terug</span>
        </head-bar-link>
      </template>
    </head-bar>

    <app-layout classes="pet-weight">
      <form class="p-2 p-md-3" action="" @submit.prevent="onSubmit">
        <Errors :errors="serverErrors" />

        <div class="mb-2">
          <label class="mb-1 d-block" for="date">
            Datum
          </label>
          <input
            class="form-control"
            type="date"
            id="date"
            v-model="form.date"
            :max="dateMax"
          >
        </div>

        <div class="mb-2">
          <label class="mb-1 d-block" for="weight_in_grams">
            Gewicht (gram)
          </label>
          <input
            class="form-control"
            type="number"
            id="weight_in_grams"
            v-model="form.weight_in_grams"
            min="0"
            max="100000"
          >
        </div>

        <div class="d-flex">
          <button
            class="btn btn--primary"
            type="submit"
            :disabled="petWeightLoadingState === 'loading'"
          >
            {{
              petWeightLoadingState === 'loading'
                ? 'Een moment geduld...'
                : 'Gewicht opslaan'
            }}
          </button>

          <button
            class="btn ml-auto"
            type="button"
            v-if="form.pet_weight_id"
            @click="onDeletePetWeight"
          >
            Verwijderen
          </button>
        </div>
      </form>
    </app-layout>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import HeadBar from '../../components/HeadBar.vue';
import HeadBarLink from '../../components/HeadBarLink.vue';
import Errors from '../../components/Errors.vue';
import AppLayout from '../AppLayout.vue';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    Errors,
    AppLayout,
    HeadBarLink,
    HeadBar,
  },

  data() {
    return {
      dateMax: format(new Date(), 'yyyy-MM-dd'),
      serverErrors: [],
      form: {
        pet_weight_id: null,
        date: format(new Date(), 'yyyy-MM-dd'),
        weight_in_grams: 1000,
      },
    };
  },

  computed: {
    ...mapGetters([
      'petWeightLoadingState',
    ]),
  },

  mounted() {
    const id = this.$route.params?.id;

    if (typeof id !== 'undefined') {
      // Empty the form to give the user some indication that we're loading the
      // new data.
      this.form.date = '';
      this.form.weight_in_grams = '';
      this.form.pet_weight_id = id;

      this.getPetWeight(id);
    }
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onSubmit() {
      this.$store.dispatch('storePetWeight', this.form)
        .then(({ data }) => {
          this.form.pet_weight_id = data.id;

          this.toast.success('Gewicht opgeslagen');
          this.$router.push({ name: 'PetWeightDetail', params: { id: data.id } });
        })
        .catch((err) => {
          this.serverErrors = !err.response
            ? ['Er ging iets mis. Probeer het later opnieuw.']
            : retrieveErrorsFromResponse(err.response);
        });
    },

    onDeletePetWeight() {
      if (!this.form.pet_weight_id) {
        return;
      }

      // eslint-disable-next-line no-alert
      if (!window.confirm('Weet je het zeker?')) {
        return;
      }

      this.$store.dispatch('destroyPetWeight', this.form.pet_weight_id)
        .then(() => {
          this.toast.success('Gewicht is verwijderd');
          this.$router.push({ name: 'Feed' });
        })
        .catch(() => {
          this.serverErrors = ['Er ging iets mis, probeer het later opnieuw'];
        });
    },

    getPetWeight(id) {
      this.$store.dispatch('getPetWeight', id)
        .then(({ data }) => {
          this.form.weight_in_grams = data.grams;
          this.form.date = format(parseISO(data.created_at), 'yyyy-MM-dd');
        });
    },
  },
};
</script>

<style scoped>
.pet-weight {
  padding-top: 3.5rem;
}
</style>
